import { template as template_43d3f8ab6dc14b0995603206871a9e30 } from "@ember/template-compiler";
const FKControlMenuContainer = template_43d3f8ab6dc14b0995603206871a9e30(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
