import { template as template_9a6c815c8b3c422a81e3c50ab3f44171 } from "@ember/template-compiler";
const WelcomeHeader = template_9a6c815c8b3c422a81e3c50ab3f44171(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
